import React, { useEffect, useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import moneyBagSvg from "../../../assets/images/money.svg";
import { FcGoogle } from "react-icons/fc";
import {
  signInWithGoogle,
  signUpWithEmailAndPassword,
} from "../../../utils/config";
import SideBanner from "../SideBanner";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../utils/Spinner";
import {
  useRegisterUserMutation,
  useSendVerificationEmailMutation,
} from "./userSlice";
import logo from "../../../assets/images/logo.png";
import darkLogo from "../../../assets/images/darkLogo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Register() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState();
  const [
    registerUser,
    { isLoading: loadingRegister, error: registerErrorMsg },
  ] = useRegisterUserMutation();
  const [
    sendVerificationEmail,
    { isLoading: loadingEmail, error: emailErrorMsg },
  ] = useSendVerificationEmailMutation();
  const login = async () => {
    setLoading(true);
    try {
      const res = await signInWithGoogle();
      console.log(res);
    } catch (error) {}
  };


  const createUser = async () => {
    try {
      setError("");
      if (!name || !email || !password || !confirmPassword) {
        return setError("Please fill all the fields.");
      }
      if (password !== confirmPassword) {
        return setPasswordError("Password and confirm password do not match");
      }

      setPasswordError("");
      setLoading(true);
      const res = await registerUser({
        email,
        password,
        name,
      }).unwrap();
      console.log(res.session);
      // const resEmail = await sendVerificationEmail().unwrap();
      // toast.success("Verification email sent successfully!");

      if (res?.session?.user?.email) {
        navigate("/dashboard");
      }
      setLoading(false);
      if (res.error) {
        setError(res.error);
      }
      setLoading(false);
    } catch (error) {
      setError(error?.data?.message);
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div className=" flex  h-screen ">
      <div className="md:w-1/2 md:block hidden   h-full overflow-y-scroll">
        <SideBanner />
      </div>
      <div className=" md:w-1/2 w-full bg-white  h-full overflow-y-scroll  px-4 md:py-10 py-8">
        <div className=" grid md:grid-cols-2 grid-cols-1   ">
          <div className="flex items-center md:justify-normal  justify-center ">
            <img src={logo} className="w-60 h-16 rounded-lg" alt="" />
          </div>
          <div className=" md:flex items-center justify-end space-x-4  hidden ">
            <div className=" text-sm">Already have an account?</div>
            <Link
              to="/login"
              className=" text-sm shadow-lg text-[#A2CC40] font-semibold rounded-full border px-4 py-2 border-gray-200 "
            >
              Login Now
            </Link>
          </div>
        </div>
        <div className="  w-full md:px-10 px-2 pt-6 ">
          <div className=" text-center text-xl font-bold ">
            Create an account Now!
          </div>

          {error?.length > 1 && (
            <div className="text-sm text-red-500 pt-4 text-center capitalize">
              {error}
            </div>
          )}
          {passwordError?.length > 1 && (
            <div className="text-sm text-red-500 pt-4 text-center capitalize">
              {passwordError}
            </div>
          )}
          <div className=" pt-6">
            <div className="text-gray-500 text-sm">Email</div>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder=" Enter Email"
              className=" border border-gray-800 py-2 px-2   shadow-lg"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className=" pt-4">
            <div className="text-gray-500 text-sm">Username</div>
            <Input
              placeholder=" Enter Username"
              className=" border border-gray-800 py-2 px-2   shadow-lg"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className=" pt-4">
            <div className="text-gray-500 text-sm">Password</div>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder=" Enter Password"
              type="password"
              className=" border border-gray-800 py-2 px-2   shadow-lg"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className=" pt-4">
            <div className="text-gray-500 text-sm">Confirm Password</div>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder=" Confirm Password"
              type="password"
              className=" border border-gray-800 py-2 px-2   shadow-lg"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className=" pt-4  flex justify-between items-center">
            <div className=" flex items-center space-x-2">
              <input
                type="checkbox"
                id="remember"
                className="  cursor-pointer "
                name="remember"
                value="remember"
              />
              <div className=" text-gray-500 text-sm">Remember me</div>
            </div>
            <Link
              to={"/login"}
              className=" text-sm text-gray-500 cursor-pointer"
            >
              Login
            </Link>
          </div>
          <div className=" flex justify-center items-center pt-4 w-full">
            <button
              className=" bg-[#13143E] text-white w-full py-1 rounded-md"
              onClick={createUser}
            >
              {loading ? <Spinner /> : " Register"}
            </button>
          </div>
          <div className=" flex  justify-center items-center pt-4 w-full"></div>
          <div className="pt-8 flex items-center justify-center">
            <div className=" text-sm text-gray-500">
              Anthonomics 2024 | All rights reserved
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Register;
