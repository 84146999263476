import React from "react";

function Join() {
  return (
    <div className=" bg-[#19223C] text-white  md:pb-10 pb-4  md:pt-20 pt-10 md:px-14 px-7 ">
      <div className=" text-4xl font-bold py-5">
        Connect with a Community of Savvy Savers
      </div>
      <div className=" py-4 pb-12 font-semibold text-gray-200 md:w-3/4">
        Over the past year, we’ve focused on building an app designed to help
        individuals gain control over their finances, and we’re thrilled to
        share it with you—for free! With our app, you’ll be able to manage your
        money more efficiently, boost your savings, reduce spending, and get a
        clear perspective on your financial health.
      </div>
      <div className="text-xs font-semibold  text-gray-300 md:w-3/4">
        We’re confident that within the next five years, we can help our users
        save and invest over $1 billion, but the true value lies in empowering
        each individual to maximize their financial potential. It’s a testament
        to what’s achievable with the right tools in hand. Begin your journey to
        financial freedom today and see how our app can help you reach your
        goals.
      </div>
    </div>
  );
}

export default Join;
