import React from "react";
import phonePic from "../../src/assets/images/phone.png";
import rocket from "../../src/assets/images/moneyapp1.png";
import androidPic from "../assets/images/android.png";
import applePic from "../assets/images/apple.svg";

function Banner() {
  return (
    <div className=" flex flex-wrap justify-between p-4 bg-white md:p-0 md:h-[600px] ">
      <div className=" md:pl-20 md:pt-28  md:w-1/2 w-full">
        <div className=" bg-[#A2CC40] w-16 h-2 mb-4" />
        <div className=" font-bold md:text-6xl text-4xl pb-4 text-gray-800">
          The <span className="text-[#A2CC40]">Financial app</span>
        </div>
        <div className=" font-bold md:text-6xl text-4xl pb-4 text-gray-800">
          Designed to work for you
        </div>

        <div className=" text-xl text-gray-800">
          Handling finances can be challenging, but you don’t have to go it
          alone. Anthonomics equips you to boost your savings, make smarter
          spending choices, stay updated, and regain control of your financial
          journey.
        </div>
        <div className=" flex items-center space-x-8 md-pt-0 pt-6">
          <a href="https://apps.apple.com/us/app/anthonomics/id6621265244">
            <img
              src={applePic}
              className=" cursor-pointer"
              height={150}
              width={150}
              alt="Apple App here"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=budget.anthonomics.app">
            <img
              src={androidPic}
              className=" cursor-pointer"
              height={150}
              width={150}
              alt="Android App here"
            />
          </a>
        </div>
      </div>
      <div className=" md:relative md:w-1/2 w-full ">
        <img
          src={rocket}
          alt="phone "
          className=" md:absolute md:right-16  md:h-[600px] h-[400px] object-cover"
        />
      </div>
    </div>
  );
}

export default Banner;
